<template>
 <p>{{ userdata }}</p>
 <p>User expires in: {{ expired }}</p>
 <button class="btn btn-danger" @click="logout">Logout</button>
</template>

<script>
 import {authFetch} from '@/lib/api.js'
 import { logout, expires } from '@/lib/auth.js'

 export default {
     name: 'User',
     data() {
         return {
             userdata: {username: '', email: ''},
             expiresIn: 0
         }
     },
     methods: {
         logout(){
             logout().then(() => {
                 this.$router.push({ name: 'Login' })
             })
         }
     },
     computed: {
         expired: function(){
             return this.expiresIn > 0 ? this.expiresIn.toString()+'s' : 'Expired'
         },
     },
     mounted(){
         authFetch('/users/user', 'GET', {})
             .then((response) => {
                 console.log(response)
             })
             .catch((error) => {
                 console.log(error.response)
             })
         this.expiresInterval = setInterval(() => {this.expiresIn = expires()}, 1000);
     },
     unmounted(){
         clearInterval(this.expiresInterval)
     }
 }
</script>
